import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import axios from 'axios'
import moment from 'moment-timezone'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

moment.tz.setDefault('Asia/Bankkok')
moment.locale('th')

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_SERVER_URL

console.log(process.env.VUE_APP_SERVER_URL)

Vue.filter('dateTime', dateTime => moment(dateTime).format('YYYY MMMM DD เวลา HH:mm'))

Vue.filter('publicImage', v => `${process.env.VUE_APP_SERVER_URL}/image/${v}`)

Vue.filter('timelineColor', status => {
  if (status === 0) return 'warning'
  if (status === 1) return 'info'
  if (status === 2) return 'success'
  return 'primary'
})

const createApp = async () => {
  const res = await axios.get('/request/type')
  store.dispatch('app/setRequestOptions', res.data.items.map(i => ({
    // eslint-disable-next-line no-underscore-dangle
    value: i._id,
    text: i.name,
  })))
  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app')
}

createApp()
